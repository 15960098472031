/* eslint-disable no-param-reassign */
import axios from 'axios';
import token from '../token';

const api = axios.create({baseURL: process.env.REACT_APP_BASE_URL});

api.interceptors.request.use(config => {
  const userToken = `Bearer ${token?.get('admin:token')}`;
  config.headers.Authorization = userToken;
  config.headers.common['Content-Type'] = 'application/json';
  return config;
});

export default api;
