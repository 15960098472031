export const formatNumberToCurrency = (number, currency) => {
  const convertStringToNumber =
    typeof number === 'string' ? Number(number) : number;

  if (currency && currency !== 'NGN') {
    // const convertedNumber = convertStringToNumber / rate;
    return new Intl.NumberFormat(`en-${currency?.substr(0, 2)}`, {
      currency,
      style: 'currency',
    }).format(convertStringToNumber || 0);
  }

  return new Intl.NumberFormat('en-NG', {
    currency: 'NGN',
    style: 'currency',
  }).format(Math.round(convertStringToNumber) || 0);
};
